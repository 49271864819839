<script setup>
import { AVATAR_IMAGE_URLS, FLAGS_IMAGE_URLS } from '../constants.js';

const Mq = useMq();
const shouldAnimate = ref(false);
const sectionRef = ref(null);
let observer = null;

onMounted(() => {
  observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      // Trigger your animation here
      shouldAnimate.value = true;
    }
  });
  observer?.observe(sectionRef.value);
});

onBeforeUnmount(() => {
  observer?.unobserve(sectionRef?.value);
});
</script>

<template>
  <section
    id="swis-section"
    ref="sectionRef"
    class="swis-section-wrapper"
  >
    <div
      class="animation-container"
      :class="{ animate: shouldAnimate }"
    >
      <img
        srcset="
          https://a.hwstatic.com/image/upload/f_auto/v1645010222/pwa/whosgoing/los-patios-card_2x_png.jpg 2x,
          https://a.hwstatic.com/image/upload/f_auto/v1645010222/pwa/whosgoing/los-patios-card_png.jpg    1x
        "
        src="https://a.hwstatic.com/image/upload/f_auto/v1645010222/pwa/whosgoing/los-patios-card_png.jpg"
        alt="Los Patios Hostel card"
        class="card"
        loading="lazy"
        width="289"
        height="328"
      />

      <!-- prettier-ignore -->
      <div>
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.mike" :flag-img-url="FLAGS_IMAGE_URLS.usa" :size="72" flag-position="2"
          border-color="aqua" class-name="avatar-usa"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.anna" :flag-img-url="FLAGS_IMAGE_URLS.poland" :size="64" flag-position="4"
          border-color="green" class-name="avatar-poland"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.eve" :flag-img-url="FLAGS_IMAGE_URLS.ireland" :size="108"
          :flag-position="Mq.isLargeScreen ? '4' : '8'" border-color="blue" class-name="avatar-ireland"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.luiza" :flag-img-url="FLAGS_IMAGE_URLS.brazil"
          :size="!Mq.isLargeScreen ? 89 : 162" :flag-position="!Mq.isLargeScreen ? '0' : '14'" border-color="blue"
          class-name="avatar-brazil"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.jenny" :flag-img-url="FLAGS_IMAGE_URLS.germany"
          :size="!Mq.isLargeScreen ? 48 : 120" :flag-position="!Mq.isLargeScreen ? '-4' : '8'" border-color="violet"
          class-name="avatar-germany"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.laura" :flag-img-url="FLAGS_IMAGE_URLS.portugal"
          :size="!Mq.isLargeScreen ? 48 : 72" flag-position="0" border-color="yellow" class-name="avatar-portugal"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.luka" :flag-img-url="FLAGS_IMAGE_URLS.italy" :size="80" flag-position="0"
          border-color="orange" class-name="avatar-italy"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.pan" :flag-img-url="FLAGS_IMAGE_URLS.china"
          :size="!Mq.isLargeScreen ? 116 : 156" :flag-position="!Mq.isLargeScreen ? '0' : '18'" border-color="orange"
          class-name="avatar-china"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.pierre" :flag-img-url="FLAGS_IMAGE_URLS.france"
          :size="!Mq.isLargeScreen ? 64 : 80" flag-position="0" border-color="pink" class-name="avatar-france"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.tom" :flag-img-url="FLAGS_IMAGE_URLS.southKorea"
          :size="!Mq.isLargeScreen ? 86 : 128" :flag-position="!Mq.isLargeScreen ? '0' : '8'" border-color="pink"
          class-name="avatar-korea"
        />
        <HomepageHwSeeWhoIsGoingAvatar
          :img-url="AVATAR_IMAGE_URLS.victoria" :flag-img-url="FLAGS_IMAGE_URLS.spain"
          :size="!Mq.isLargeScreen ? 72 : 96" flag-position="0" border-color="aqua" class-name="avatar-spain"
        />

        <HomepageHwSeeWhoIsGoingDot :size="12" :fill="false" color="pink" class-name="dot-1" />
        <HomepageHwSeeWhoIsGoingDot :size="12" :fill="false" color="violet" class-name="dot-2" />
        <HomepageHwSeeWhoIsGoingDot :size="10" color="green" class-name="dot-3" />
        <HomepageHwSeeWhoIsGoingDot :size="10" color="pink" class-name="dot-4" />
        <HomepageHwSeeWhoIsGoingDot :size="20" color="orange" class-name="dot-5" />
        <HomepageHwSeeWhoIsGoingDot :size="20" color="red" class-name="dot-6" />
        <HomepageHwSeeWhoIsGoingDot :size="16" :fill="false" color="aqua" class-name="dot-7" />
        <HomepageHwSeeWhoIsGoingDot :size="16" color="aqua" class-name="dot-8" />
        <HomepageHwSeeWhoIsGoingDot :size="16" :fill="false" color="violet" class-name="dot-9" />
        <HomepageHwSeeWhoIsGoingDot :size="16" color="yellow" class-name="dot-10" />
        <HomepageHwSeeWhoIsGoingDot :size="18" color="yellow" class-name="dot-11" />
      </div>
    </div>

    <div class="text">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h2
        class="text-header"
        v-html="$t('t_SOCIALCUESHEADER')"
      />
      <p class="text-body">
        {{ $t('t_SOCIALCUESBODY') }}
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: wds-rem(650px);
  overflow-x: hidden;

  /* prettier-ignore */
  &.animate {
    @media screen and (width >=1024px) and (prefers-reduced-motion: no-preference) {
      .card {
        animation: card-rotate 2s cubic-bezier(0.6, -0.28, 0.69, 1.58) forwards;

        @keyframes card-rotate {
          from {
            transform: rotate(15deg);
          }

          to {
            transform: rotate(-3deg);
          }
        }
      }

      .avatar {
        animation-timing-function: cubic-bezier(.6, -0.28, .69, 1.58);
        animation-duration: 2s;
        animation-fill-mode: forwards;

        &-usa {
          animation-name: swig-animation-usa
        }

        &-poland {
          animation-name: swig-animation-poland
        }

        &-ireland {
          animation-name: swig-animation-ireland
        }

        &-brazil {
          animation-name: swig-animation-brazil
        }

        &-germany {
          animation-name: swig-animation-germany
        }

        &-portugal {
          animation-name: swig-animation-portugal
        }

        &-italy {
          animation-name: swig-animation-italy
        }

        &-china {
          animation-name: swig-animation-china
        }

        &-france {
          animation-name: swig-animation-france
        }

        &-korea {
          animation-name: swig-animation-korea
        }

        &-spain {
          animation-name: swig-animation-spain
        }
      }

      @keyframes swig-animation-usa {
        from {
          transform: translate(125px, -20px);
        }

        to {
          transform: translate(145px, -20px);
        }
      }

      @keyframes swig-animation-poland {
        from {
          transform: translate(-170px, -60px);
        }

        to {
          transform: translate(-200px, -70px);
        }
      }

      @keyframes swig-animation-ireland {
        from {
          transform: translate(140px, 30px);
        }

        to {
          transform: translate(400px, 30px);
        }
      }

      @keyframes swig-animation-brazil {
        from {
          transform: translate(-100px, 0);
        }

        to {
          transform: translate(-540px, -180px);
        }
      }

      @keyframes swig-animation-germany {
        from {
          transform: translate(-100px, 160px);
        }

        to {
          transform: translate(-460px, 150px);
        }
      }

      @keyframes swig-animation-portugal {
        from {
          transform: translate(-140px, -80px);
        }

        to {
          transform: translate(-260px, -195px);
        }
      }

      @keyframes swig-animation-italy {
        from {
          transform: translate(110px, -110px);
        }

        to {
          transform: translate(130px, -190px);
        }
      }

      @keyframes swig-animation-china {
        from {
          transform: translate(-100px, 100px);
        }

        to {
          transform: translate(-730px, 20px);
        }
      }

      @keyframes swig-animation-france {
        from {
          transform: translate(-40px, 60px);
        }

        to {
          transform: translate(-270px, 60px);
        }
      }

      @keyframes swig-animation-korea {
        from {
          transform: translate(120px, 0);
        }

        to {
          transform: translate(500px, -180px);
        }
      }

      @keyframes swig-animation-spain {
        from {
          transform: translate(120px, 90px);
        }

        to {
          transform: translate(230px, 100px);
        }
      }

      .dot {
        animation-timing-function: cubic-bezier(0.6, -0.28, 0.69, 1.58);
        animation-duration: 2s;
        animation-fill-mode: forwards;

        &-1 {
          animation-name: swig-animation-dot-1;
        }

        &-2 {
          animation-name: swig-animation-dot-2;
        }

        &-3 {
          animation-name: swig-animation-dot-3;
        }

        &-4 {
          animation-name: swig-animation-dot-4;
        }

        &-5 {
          animation-name: swig-animation-dot-5;
        }

        &-6 {
          animation-name: swig-animation-dot-6;
        }

        &-7 {
          animation-name: swig-animation-dot-7;
        }

        &-8 {
          animation-name: swig-animation-dot-8;
        }

        &-9 {
          animation-name: swig-animation-dot-9;
        }

        &-10 {
          animation-name: swig-animation-dot-10;
        }

        &-11 {
          animation-name: swig-animation-dot-11;
        }
      }

      @keyframes swig-animation-dot-1 {
        from {
          transform: translate(200px, -140px);
        }

        to {
          transform: translate(300px, -170px);
        }
      }

      @keyframes swig-animation-dot-2 {
        from {
          transform: translate(-120px, 20px);
        }

        to {
          transform: translate(-530px, 30px);
        }
      }

      @keyframes swig-animation-dot-3 {
        from {
          transform: translate(230px, -70px);
        }

        to {
          transform: translate(330px, -100px);
        }
      }

      @keyframes swig-animation-dot-4 {
        from {
          transform: translate(-180px, 140px);
        }

        to {
          transform: translate(-250px, 160px);
        }
      }

      @keyframes swig-animation-dot-5 {
        from {
          transform: translate(-140px, -120px);
        }

        to {
          transform: translate(-350px, -175px);
        }
      }

      @keyframes swig-animation-dot-6 {
        from {
          transform: translate(140px, -80px);
        }

        to {
          transform: translate(570px, -220px);
        }
      }

      @keyframes swig-animation-dot-7 {
        from {
          transform: translate(270px, 0);
        }

        to {
          transform: translate(530px, -30px);
        }
      }

      @keyframes swig-animation-dot-8 {
        from {
          transform: translate(-100px, 0);
        }

        to {
          transform: translate(-260px, -25px);
        }
      }

      @keyframes swig-animation-dot-9 {
        from {
          transform: translate(-40px, 60px);
        }

        to {
          transform: translate(170px, 70px);
        }
      }

      @keyframes swig-animation-dot-10 {
        from {
          transform: translate(120px, 0);
        }

        to {
          transform: translate(310px, 35px);
        }
      }

      @keyframes swig-animation-dot-11 {
        from {
          transform: translate(-240px, 100px);
        }

        to {
          transform: translate(-550px, 210px);
        }
      }
    }
  }
}

/* prettier-ignore */
.avatar {
  &-usa {
    transform: translate(125px, -90px);
    z-index: 102;
  }

  &-poland {
    transform: translate(-60px, -220px);
    z-index: 103;
  }

  &-ireland {
    transform: translate(170px, 30px);
    z-index: 106;
  }

  &-brazil {
    transform: translate(-220px, -155px);
    z-index: 106;
  }

  &-germany {
    transform: translate(-330px, -135px);
    z-index: 104;
  }

  &-portugal {
    transform: translate(-260px, -195px);
    z-index: 102;
  }

  &-italy {
    transform: translate(240px, -200px);
    z-index: 103;
  }

  &-china {
    transform: translate(-210px, 70px);
    z-index: 105;
  }

  &-france {
    transform: translate(-300px, 50px);
    z-index: 101;
  }

  &-korea {
    transform: translate(370px, -150px);
    z-index: 105;
  }

  &-spain {
    transform: translate(120px, 160px);
    z-index: 104;
  }
}

/* prettier-ignore */
@media screen and (width >=1368px) {
  .avatar {
    &-usa {
      transform: translate(125px, -20px);
    }

    &-poland {
      transform: translate(-170px, -60px);
    }

    &-ireland {
      transform: translate(140px, 30px);
    }

    &-brazil {
      transform: translate(-100px, 0);
    }

    &-germany {
      transform: translate(-100px, 160px);
    }

    &-portugal {
      transform: translate(-140px, -80px);
    }

    &-italy {
      transform: translate(110px, -110px);
    }

    &-china {
      transform: translate(-100px, 100px);
    }

    &-france {
      transform: translate(-40px, 60px);
    }

    &-korea {
      transform: translate(120px, 0);
    }

    &-spain {
      transform: translate(120px, 90px);
    }
  }
}

/* prettier-ignore */
.dot {
  &-1 {
    transform: translate(20px, -200px);
  }

  &-2 {
    transform: translate(-90px, -240px);
  }

  &-3 {
    transform: translate(-90px, 210px);
  }

  &-4 {
    transform: translate(-165px, 20px);
  }

  &-5 {
    transform: translate(-160px, -185px);
  }

  &-6 {
    transform: translate(-190px, -15px);
  }

  &-7 {
    transform: translate(310px, 80px);
  }

  &-8 {
    transform: translate(-260px, -45px);
  }

  &-9 {
    transform: translate(60px, 220px);
  }

  &-10 {
    transform: translate(100px, -200px);
  }

  &-11 {
    transform: translate(350px, -230px);
  }
}

/* prettier-ignore */
@media screen and (width >=1368px) {
  .dot {
    &-1 {
      transform: translate(200px, -140px);
    }

    &-2 {
      transform: translate(-120px, 20px);
    }

    &-3 {
      transform: translate(230px, -70px);
    }

    &-4 {
      transform: translate(-180px, 140px);
    }

    &-5 {
      transform: translate(-140px, -120px);
    }

    &-6 {
      transform: translate(140px, -80px);
    }

    &-7 {
      transform: translate(270px, 0);
    }

    &-8 {
      transform: translate(-100px, 0);
    }

    &-9 {
      transform: translate(-40px, 60px);
    }

    &-10 {
      transform: translate(120px, 0);
    }

    &-11 {
      transform: translate(-240px, 100px);
    }
  }
}

.swis-section-wrapper {
  padding-bottom: wds-rem(80px);
  background-color: $wds-color-purple;
  clip-path: polygon(0 calc(100% - #{$wds-spacing-xxl}), 0 #{$wds-spacing-xxl}, 100% 0, 100% 100%);
  position: relative;

  @media screen and (width >=768px) {
    height: wds-rem(821px);
  }

  @media screen and (width >=1024px) {
    height: wds-rem(851px);
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $wds-color-white;
  text-align: center;
  margin-top: wds-rem(-50px);
  padding-left: $wds-spacing-m;
  padding-right: $wds-spacing-m;

  &-header {
    font-family: $font-family-poppins;

    @include title-1-bld;

    @media screen and (width >=1024px) {
      font-size: wds-rem(64px);
      line-height: wds-rem(64px);
      font-weight: 700;
    }

    :deep(span) {
      color: $wds-color-sunset-light;
    }
  }

  &-body {
    @include title-6-bld;

    margin-top: $wds-spacing-l;
    font-family: $font-family-inter;
    margin-bottom: $wds-spacing-xl;
  }
}

.card {
  position: absolute;
  top: wds-rem(182px);
  left: calc(50% - 139px);
  transform: rotate(-3deg);
  z-index: $wds-z-index-toast;
  border-radius: $wds-border-radius-xl;
  box-shadow: $wds-shadow-dark-xl;

  @media screen and (width >=1368px) {
    transform: rotate(15deg);
  }
}
</style>
